<template>
  <XForm ref="tagsFilterForm" class="tag-query-text-field" @input="$emit('update:is-valid', $event)">
    <XTextField
        :required="required"
        ref="textField"
        :id="id"
        :label="label ? label : 'Tags Filter'"
        :value="localValue"
        @input="handleImmediateInput"
        :rules="computedRules"
        :tooltip="tagsFilterTooltip"
        @keydown.up="moveSelection(-1)"
        @keydown.down="moveSelection(1)"
        @keydown.enter="selectSuggestion"
        @focus="handleFocus(true)"
        @blur="handleFocus(false)"
        :help="help"
    />
    <div ref="suggestionsContainer" v-show="showSuggestions" class="suggestions">
      <v-list dense>
        <v-list-item
            v-for="(suggestion, i) of suggestions"
            :key="i"
            :class="`suggestion-list-item ${i === selection ? 'cursor' : ''}`"
            @click="addSuggestion(suggestion.suggestion, suggestion.mask)">
          <v-list-item-content>
            <v-list-item-title>
              {{ suggestion.beforeMask }}<span class="v-list-item__mask">{{
                suggestion.mask
              }}</span>{{ suggestion.afterMask }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </XForm>
</template>

<script>
import XTextField from '@/components/basic/XTextField.vue';
import XForm from '@/components/basic/XForm.vue';
import explorerStatusService from '@/js/services/ExplorerStatusService';
import cockpitExplorerService from '@/js/services/CockpitExplorerService';
import cockpitSimService from '@/js/services/CockpitSimService';

export default {
  name: 'TagQueryTextField',
  components: {
    XForm,
    XTextField,
  },
  props: {
    required: {
      type: Boolean,
      default: undefined,
    },
    value: String,
    id: String,
    currentProject: Boolean,
    scrollContainer: HTMLDivElement,
    help: String,
    label: String,
    type: {
      type: String,
      default: 'explorer',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    allowTestVariables: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: '',
      tagsFilterRules: [],
      tagsFilterTooltip: undefined,
      tags: [],
      suggestions: [],
      focus: false,
      immediateValue: '',
      delay: 4000,
      selection: -1,
      maxSuggestions: 20,
      latestCheckValue: '',
      debouncing: false,
      latestDebounceValue: '',
    };
  },
  created() {
    let value = this.value;
    let containsTestVariables = false;
    if (this.allowTestVariables) {
      value = value.replace(/\${[\w:.]+}/, 'test-variable-tag');
      containsTestVariables = value !== this.value;
      this.$emit('update:contains-test-variable', containsTestVariables);
    }
    if (this.type === 'explorer') {
      if (!containsTestVariables) {
        explorerStatusService.checkTagSyntaxCb(value, (response) => {
          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            const tagsFilterForm = this.$refs.tagsFilterForm;
            if (tagsFilterForm) tagsFilterForm.validate();
          });
          this.$emit('count', response.count);
        }, (error) => {
          const data = error.response.data;
          if (data.error === 'ESS_CREATE_TAG_QUERY') {
            error = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
            this.tagsFilterTooltip = error;
            this.tagsFilterRules = [v => v !== value];
            this.$nextTick(() => {
              this.$refs.tagsFilterForm.validate();
            });
          }
        });
      }

      if (!this.currentProject) {
        cockpitExplorerService.getExplorerTags((tags) => {
          this.tags = tags;
        });
      } else {
        cockpitExplorerService.getExplorerTagsForCurrentProject((tags) => {
          this.tags = tags;
        });
      }
    } else if (this.type === 'sim') {
      if (!containsTestVariables) {
        cockpitSimService.getSimCountByTagQuery(value, (response) => {
          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            const tagsFilterForm = this.$refs.tagsFilterForm;
            if (tagsFilterForm) tagsFilterForm.validate();
          });
          this.$emit('count', response.count);
        }, (error) => {
          const data = error.response.data;
          if (data.error === 'CSS_CREATE_TAG_QUERY') {
            error = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
            this.tagsFilterTooltip = error;
            this.tagsFilterRules = [v => v !== value];
            this.$nextTick(() => {
              this.$refs.tagsFilterForm.validate();
            });
          }
        });
      }

      cockpitSimService.getTags((tags) => {
        this.tags = tags;
      });
    } else {
      console.error(`Unknown tag query text field type: ${this.type}`);
    }
  },
  watch: {
    value: {
      handler(value) {
        if (value === this.localValue) return;
        this.localValue = value;
      },
      immediate: true,
    },
    suggestions(value) {
      if (!value) this.selection = -1;
    },
  },
  computed: {
    showSuggestions() {
      return this.focus && this.suggestions.length > 0;
    },
    computedRules() {
      return this.rules.concat(this.tagsFilterRules);
    },
  },
  methods: {
    handleImmediateInput(value) {
      this.immediateValue = value;
      this.tagsFilterTooltip = undefined;
      let results = [];
      if (this.tags.length) {
        if (value && value.length) {
          const regex = /[a-zA-Z0-9!_\-=.]+/;
          let matches = value.match(regex);
          const caretPosition = this.$refs.textField.$refs.input.$refs.input.selectionStart;
          let offset = 0;
          while (matches != null) {
            offset--;
            matches = value.charAt(caretPosition + offset).match(regex);
          }
          offset++;
          const match = value.substring(caretPosition + offset, caretPosition);
          results = this.tags.filter(function (x) {
            if (this.count < this.maxSuggestions && x.includes(match)) {
              this.count++;
              return true;
            }
            return false;
          }, {
            count: 0,
            maxSuggestions: this.maxSuggestions,
          });
          for (let i = 0; i < results.length; i++) {
            const index = results[i].indexOf(match);
            results[i] = {
              suggestion: results[i],
              beforeMask: results[i].substring(0, index),
              mask: match,
              afterMask: results[i].substring(index + match.length),
            };
          }
        }
      }
      this.suggestions = results;

      this.scrollToSuggestions();

      this.latestDebounceValue = value;
      this.debouncing = true;
      setTimeout(() => {
        if (this.debouncing && value === this.latestDebounceValue) {
          this.emitInput(value);
          this.debouncing = false;
        }
      }, this.delay);
    },
    emitInput(value) {
      this.localValue = value;

      const originalValue = value;
      this.latestCheckValue = value;

      if (this.allowTestVariables) {
        value = value.replace(/\${[\w:.]+}/, 'test-variable-tag');
        this.$emit('update:contains-test-variable', value !== originalValue);
      }

      if (this.type === 'explorer') {
        explorerStatusService.checkTagSyntaxCb(value, (response) => {
          if (this.latestCheckValue !== originalValue) return;

          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            this.$refs.tagsFilterForm.validate();
          });
          this.$emit('input', originalValue);
          this.$emit('count', response.count);
        }, (error) => {
          if (this.latestCheckValue !== originalValue) return;

          const data = error.response.data;
          let errorMessage = 'Tag query syntax error.';
          if (data.error === 'ESS_TAG_QUERY') {
            errorMessage = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
          }
          this.tagsFilterTooltip = errorMessage;
          this.tagsFilterRules = [v => v !== originalValue];
          this.$nextTick(() => {
            this.$refs.tagsFilterForm.validate();
          });
        });
      } else if (this.type === 'sim') {
        cockpitSimService.getSimCountByTagQuery(value, (response) => {
          if (this.latestCheckValue !== originalValue) return;

          this.tagsFilterTooltip = undefined;
          this.tagsFilterRules = [];
          this.$nextTick(() => {
            this.$refs.tagsFilterForm.validate();
          });
          this.$emit('input', originalValue);
          this.$emit('count', response.count);
        }, (error) => {
          if (this.latestCheckValue !== originalValue) return;

          const data = error.response.data;
          let errorMessage = 'Tag query syntax error.';
          if (data.error === 'CSS_TAG_QUERY') {
            errorMessage = data.message.substring(0, 1).toUpperCase() + data.message.substring(1) + '.';
          }
          this.tagsFilterTooltip = errorMessage;
          this.tagsFilterRules = [v => v !== originalValue];
          this.$nextTick(() => {
            this.$refs.tagsFilterForm.validate();
          });
        });
      } else {
        console.error(`Unknown tag query text field type: ${this.type}`);
      }
    },
    addSuggestion(suggestion, mask) {
      this.debouncing = false;
      let value = this.immediateValue;
      const caretPosition = this.$refs.textField.$refs.input.$refs.input.selectionStart;
      value = value.substring(0, caretPosition - mask.length) + suggestion + value.substring(caretPosition);
      setTimeout(() => {
        const newPosition = caretPosition - mask.length + suggestion.length;
        this.$refs.textField.$refs.input.$refs.input.focus();
        this.$refs.textField.$refs.input.$refs.input.setSelectionRange(newPosition, newPosition);
      }, 16);
      this.emitInput(value);
      this.suggestions = [];
      this.$nextTick(() => {
        this.$refs.textField.$refs.input.focus();
      });
    },
    handleFocus(value) {
      setTimeout(() => {
        this.focus = value;
      }, 500);
    },
    moveSelection(value) {
      let selection = this.selection;
      selection += value;
      if (selection < 0) selection = this.suggestions.length - 1;
      else if (selection > this.suggestions.length - 1) selection = 0;
      this.selection = selection;
    },
    selectSuggestion() {
      if (this.selection >= 0) {
        const suggestion = this.suggestions[this.selection];
        this.addSuggestion(suggestion.suggestion, suggestion.mask);
      }
    },
    scrollToSuggestions() {
      if (!this.scrollContainer) return;
      this.$nextTick(() => {
        const suggestionsContainer = this.$refs.suggestionsContainer;
        if (suggestionsContainer && this.scrollContainer) {
          const containerRect = this.scrollContainer.getBoundingClientRect();
          const suggestionsRect = suggestionsContainer.getBoundingClientRect();

          if (suggestionsRect.bottom > containerRect.bottom) {
            this.$emit('scroll-request', suggestionsRect.bottom - containerRect.bottom);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.tag-query-text-field {
  position: relative;
}

.suggestions {
  box-shadow: 0 4px 6px 0 rgb(32 33 36 / 28%);
  overflow-y: auto;
  max-height: 304px;
  position: absolute;
  z-index: 2;
}

.suggestion-list-item {
  cursor: pointer;
  transition: .3s cubic-bezier(.25, .8, .5, 1);
}

.suggestion-list-item.cursor {
  background-color: var(--v-list-item-cursor-base);
}

.suggestion-list-item:not(.cursor):hover {
  background-color: var(--v-list-item-hover-base);
}
</style>
